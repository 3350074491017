import { template as template_f3f7db0d52974f6992f092db459f2bf0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_f3f7db0d52974f6992f092db459f2bf0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
