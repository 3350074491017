import { template as template_3d411450f4494536be98b5309dccad76 } from "@ember/template-compiler";
const WelcomeHeader = template_3d411450f4494536be98b5309dccad76(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
